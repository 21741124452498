<template>
	<div class="builder-header">
		<div class="builder-header__item">
			<ZyroButton
				v-if="!showExitPopup"
				class="builder-header__separator"
				theme="header"
				color="white"
				icon-left="chevron-left-small"
				icon-only-up-to-media="header-desktop"
				to="builder"
				data-qa="builder-header-btn-exit"
			>
				{{ $t('common.exit') }}
			</ZyroButton>
			<ZyroPopup
				v-else
				reposition
				:show-close-button="false"
				:offset="{
					top: 55,
					left: 60
				}"
			>
				<template #trigger>
					<ZyroButton
						class="builder-header__separator"
						theme="header"
						color="white"
						icon-left="chevron-left-small"
						icon-only-up-to-media="header-desktop"
					>
						{{ $t('common.exit') }}
					</ZyroButton>
				</template>
				<BlogExitPopup
					:is-post-published="isPostPublished"
					@click="$emit('exit', $event)"
				/>
			</ZyroPopup>
		</div>

		<!-- Right side -->
		<div class="builder-header__item">
			<!-- Mobile/Desktop view toggle button -->
			<div class="builder-header__separator show-tablet-up" />
			<BuilderHeaderScreenToggleButton class="show-tablet-up" />

			<!-- Undo button -->
			<div class="builder-header__separator show-tablet-up" />
			<ZyroButton
				:disabled="isUndoDisabled"
				data-qa="builderheader-menuitem-undo"
				class="show-tablet-up"
				theme="header"
				color="white"
				icon="arrow-back"
				:title="$t('common.undo')"
				@click="undoWebsite"
			/>

			<!-- Redo button -->
			<div class="builder-header__separator show-tablet-up" />
			<ZyroButton
				:disabled="isRedoDisabled"
				data-qa="builderheader-menuitem-redo"
				class="show-tablet-up"
				theme="header"
				color="white"
				icon="arrow-forward"
				:title="$t('common.redo')"
				@click="redoWebsite"
			/>

			<!-- Post settings button -->
			<ZyroButton
				theme="header"
				icon-left="settings"
				icon-right="chevron-down-small"
				:title="$t('common.postSettings')"
				color="white"
				class="builder-header__separator"
				data-qa="builder-header-btn-postsettings"
				@click="$emit('open-post-settings')"
			>
				{{ $t('common.postSettings') }}
			</ZyroButton>

			<!-- Preview button -->
			<div class="builder-header__separator" />
			<ZyroButton
				data-qa="builderheader-menuitem-previewbutton"
				theme="header"
				color="white"
				@click="$router.push({ name: PREVIEW_ROUTE })"
			>
				{{ $t('common.preview') }}
			</ZyroButton>

			<!-- Publish/Save button -->
			<ZyroButton
				class="publish-button"
				theme="header"
				:color="publishButtonColor"
				data-qa="builder-header-btn-publishpost"
				@click="$emit('handle-publish')"
			>
				{{ publishButtonText }}
			</ZyroButton>
		</div>
	</div>
</template>

<script>
import {
	mapGetters,
	mapMutations,
} from 'vuex';

import BlogExitPopup from '@/components/builder-view/components/BlogExitPopup.vue';
import { PREVIEW_ROUTE } from '@/router';

import BuilderHeaderScreenToggleButton from './buttons/BuilderHeaderScreenToggleButton.vue';

export default {
	components: {
		BuilderHeaderScreenToggleButton,
		BlogExitPopup,
	},
	props: {
		publishButtonColor: {
			type: String,
			default: '',
		},
		publishButtonText: {
			type: String,
			default: '',
		},
		showExitPopup: {
			type: Boolean,
			default: false,
		},
		isPostPublished: {
			type: Boolean,
			default: false,
		},
	},
	setup() {
		return { PREVIEW_ROUTE };
	},
	computed: mapGetters('undoRedo', [
		'isUndoDisabled',
		'isRedoDisabled',
	]),
	methods: mapMutations('undoRedo', [
		'undoWebsite',
		'redoWebsite',
	]),
};
</script>

<style scoped lang="scss">
@import "./BuilderHeader";
</style>
