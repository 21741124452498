<template>
	<ul class="popup-content">
		<li
			v-for="button in buttons"
			:key="`${button.id}-button`"
		>
			<ZyroButton
				theme="plain"
				:title="button.title"
				class="z-body-small"
				:class="{ 'popup-content__button': button.id === 'discard' }"
				@click="$emit('click', button.id)"
			>
				{{ button.text }}
			</ZyroButton>
		</li>
	</ul>
</template>

<script>
import i18n from '@/i18n/setup';

const publishedButtons = [
	{
		id: 'publish',
		title: i18n.t('common.saveAndPublish'),
		text: i18n.t('common.saveAndPublish'),
	},
	{
		id: 'discard',
		title: i18n.t('common.discardChanges'),
		text: i18n.t('common.discardChanges'),
	},
];

const unpublishedButtons = [
	{
		id: 'save',
		title: i18n.t('common.saveToDraft'),
		text: i18n.t('common.saveToDraft'),
	},
	{
		id: 'publish',
		title: i18n.t('common.publish'),
		text: i18n.t('common.publish'),
	},
	{
		id: 'discard',
		title: i18n.t('common.discardChanges'),
		text: i18n.t('common.discardChanges'),
	},
];

export default {
	props: {
		isPostPublished: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		buttons() {
			return this.isPostPublished
				? this.$options.publishedButtons
				: this.$options.unpublishedButtons;
		},
	},
	publishedButtons,
	unpublishedButtons,
};
</script>

<style lang="scss" scoped>
.popup-content {
	overflow: hidden;
	list-style: none;

	&__button {
		color: $secondary;
	}
}
</style>
