<template>
	<div>
		<BuilderHeaderBlog
			v-if="currentPage"
			class="page-layout__header page-layout__header--sticky"
			:show-exit-popup="isChangesMade"
			:publish-button-text="publishButtonText"
			:publish-button-color="!postPublished && !isChangesMade ? 'red' : 'black'"
			:is-post-published="postPublished"
			@exit="exit"
			@handle-publish="handlePublishClick"
			@open-post-settings="openModal({
				name: 'BlogPostSettingsModal',
				settings: { blogPostId: currentPageId },
			})"
		/>
		<BuilderRoot class="page-layout" />
	</div>
</template>

<script>
import {
	mapState,
	mapGetters,
	mapMutations,
	mapActions,
} from 'vuex';

import EventLogApi from '@/api/EventLogApi';
import BuilderHeaderBlog from '@/components/builder-view/headers/BuilderHeaderBlog.vue';
import BuilderRoot from '@/components/builder-view/views/BuilderRoot/BuilderRoot.vue';
import { BUILDER_ROUTE } from '@/router';
import { BLOG_DRAWER } from '@/store/builder/constants/drawers';
import {
	mapActionsGui,
	OPEN_MODAL,
	CLOSE_DRAWER,
} from '@/store/builder/gui';
import {
	mapActionsNotifications,
	NOTIFY,
} from '@/store/builder/notifications';
import { cloneDeep } from '@/utils/object';

export default {
	components: {
		BuilderHeaderBlog,
		BuilderRoot,
	},
	beforeRouteEnter(to, from, next) {
		next((vm) => {
			// Preview mode can change the current page. Need to ensure blogpost page is the current page.
			vm.changePage({ pageId: vm.$route.params.postId });
			if (from.name === 'builder') {
				vm.setWebsiteBeforeEdit(cloneDeep(vm.website));
				vm.setIsChangesMade(false);
				vm.lockCustomHistoryIndex();
			}
		});
	},
	// If going to other route, unset edit mode, post id and return builder history.
	beforeRouteLeave(to, from, next) {
		if (to.name === 'preview') {
			next();

			return;
		}

		next();
		this.unlockCustomHistoryIndex();
		this.setBlogEditMode(false);
		this.changePage();
		this.closeDrawer(BLOG_DRAWER);
	},
	computed: {
		...mapState([
			'website',
			'domain',
		]),
		...mapState('blog', [
			'websiteBeforeEdit',
			'isChangesMade',
		]),
		...mapState('pages', ['currentPageId']),
		...mapState('undoRedo', [
			'history',
			'redoHistory',
		]),
		...mapState('user', ['user']),
		...mapGetters('pages', ['currentPage']),
		...mapGetters('undoRedo', ['isUndoDisabled']),
		postPublished() {
			return !this.currentPage?.customData?.hidden;
		},
		publishButtonText() {
			if (this.postPublished) {
				return this.$t('common.saveAndPublish');
			}

			if (this.isChangesMade) {
				return this.$t('common.publishPost');
			}

			return this.$t('common.publish');
		},
	},
	watch: {
		// Both watchers are seeking for ANY changes in history
		'history.length': function checkLength() {
			this.handleChangesMadeCheck();
		},
		'redoHistory.length': function checkLength() {
			this.handleChangesMadeCheck();
		},
	},
	/*
	 * Persisted state can't be used to keep current post id.
	 * So, a non-blog post id can be loaded ONLY on blog page refresh.
	 * On that page refresh, `initBuilder` method sets the page to homepage.
	 * So, if homepageId is the currentPageId, we need to change it to blogPostId from params.
	 */
	beforeMount() {
		this.setBlogEditMode(true);
		if (this.currentPageId === this.website.meta.homepageId) {
			this.changePage({ pageId: this.$route.params.postId });
		}
	},
	methods: {
		...mapMutations('blog', [
			'setBlogEditMode',
			'setWebsiteBeforeEdit',
			'setIsChangesMade',
		]),
		...mapMutations(['setWebsite']),
		...mapMutations('undoRedo', [
			'lockCustomHistoryIndex',
			'unlockCustomHistoryIndex',
		]),
		...mapActionsGui({
			openModal: OPEN_MODAL,
			closeDrawer: CLOSE_DRAWER,
		}),
		...mapActionsNotifications({ notify: NOTIFY }),
		...mapActions('pages', [
			'changePage',
			'setPageData',
		]),
		...mapActions('blog', ['calculateReadTime']),
		...mapActions([
			'saveWebsite',
			'updateWebsite',
		]),
		...mapActions('gui', ['setEditMode']),

		// If undo is enabled and changes made is set to false, set it to true
		handleChangesMadeCheck() {
			if (!this.isUndoDisabled && !this.isChangesMade) {
				this.setIsChangesMade(true);
			}
		},

		// If post is visible OR invisible and no changes made - publish and save, otherwise just save.
		async handlePublishClick() {
			// Set edit mode to false and wait for all changes to stack up, then publish site.
			this.setEditMode(false);
			await this.$nextTick();
			if (this.postPublished || (!this.postPublished && !this.isChangesMade)) {
				this.publish();

				return;
			}

			this.save();
		},

		// Handle exit popup - call appropriate action according to which button was clicked
		exit(event) {
			this[event]();
			this.$router.push({ name: BUILDER_ROUTE });
		},
		async publish() {
			this.save();
			this.setPageData({
				type: 'blog',
				payload: {
					pageId: this.currentPageId,
					data: {
						customData: {
							hidden: false,
							date: new Date().toISOString(),
						},
					},
				},
			});
			// When site is not published
			if (!this.domain) {
				this.notify({
					origin: 'Blog.vue',
					message: 'You need to publish your site first if you want to publish your posts.',
				});

				return;
			}

			await this.saveWebsite();
			this.updateWebsite({ showModal: false });

			EventLogApi.logEvent({ eventName: 'builder.blog.publish_post' });
			window.hj('identify', this.user.id, { 'builder.blog.publish_post': true });
		},
		save() {
			this.setWebsiteBeforeEdit(cloneDeep(this.website));
			this.calculateReadTime({ pageId: this.currentPageId });
			this.setIsChangesMade(false);
		},
		discard() {
			this.setWebsite({ website: this.websiteBeforeEdit });
		},
	},
};
</script>

<style lang="scss" scoped>
@import './PageLayout';
</style>
